import { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  List,
  ListItem,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  IconButton,
  Divider
} from '@material-ui/core';
// hooks
import useLocalStorage from 'hooks/useLocalStorage';
import useAuth from 'hooks/useAuth';

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    color: theme.palette.text.primary
  })
);

const ListItemStyle = styled((props) => <ListItem button disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
  user: PropTypes.object
};

function NavItem({ item, active, user }) {
  const theme = useTheme();
  const isActiveRoot = active(item.url);
  const { title, url, icon, info, subchild } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const updatedURL = url.replace(':sitecode', JSON.parse(localStorage.getItem('selectedSite'))?.[user.id]?.domain);
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (subchild) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subchild.map((item) => {
              const { title, url, hide } = item;
              const updatedURL = url.replace(
                ':sitecode',
                JSON.parse(localStorage.getItem('selectedSite'))?.[user.id]?.domain
              );

              const isActiveSub = active(url);
              if (!hide)
                return (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={updatedURL}
                    sx={{
                      ...(isActiveSub && activeSubStyle)
                    }}
                  >
                    <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: 'flex',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'text.disabled',
                          transition: (theme) => theme.transitions.create('transform'),
                          ...(isActiveSub && {
                            transform: 'scale(2)',
                            bgcolor: 'primary.main'
                          })
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle>
                );
              return false;
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={updatedURL}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  const [selected, setSelected] = useState('');
  const { user } = useAuth();

  const setSelectedAndOpenState = async () => {
    navConfig.map((route) => {
      if (pathname.indexOf(route.url) > -1) {
        setSelected(route.name);
      } else if (pathname.indexOf('/site/') !== -1 && localStorage.getItem('selectedSite')?.[user.id]) {
        const trimmedUrls = pathname.split('/');
        trimmedUrls[trimmedUrls.indexOf('site') + 1] = ':sitecode';
        const urlString = trimmedUrls.join('/');
        if (urlString.indexOf(route.url) > -1) {
          setSelected(route.name);
        }
      }
      return true;
    });
  };

  useEffect(() => {
    setSelectedAndOpenState();
  }, [pathname]);

  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="row" height="100%">
      <>
        <Box bgcolor={theme.palette.background.navbar} width="58px" mx="auto">
          {navConfig.map((list) => {
            const { title, name } = list;
            return (
              <IconButton
                key={title}
                style={{
                  fontSize: '36px',
                  borderRadius: 0,
                  width: '58px',
                  height: '58px',
                  backgroundColor: name === selected && theme.palette.background.paper,
                  color: name === selected && theme.palette.primary.main
                }}
                onClick={() => setSelected(name)}
              >
                <list.icon style={{ height: '24px', width: '24px' }} />
              </IconButton>
            );
          })}
        </Box>
        <Box width="100%">
          {navConfig.map((list) => {
            const { title, child, name } = list;
            let component = null;
            if (name === selected) {
              component = (
                <Fragment key={title}>
                  <ListSubheaderStyle>{title}</ListSubheaderStyle>
                  <Divider variant="middle" />
                  <Box mb={2} />
                  {child.map((item, index) => (
                    <NavItem key={index} item={item} active={match} user={user} />
                  ))}
                </Fragment>
              );
            }
            return component;
          })}
        </Box>
      </>
    </Box>
  );
}
