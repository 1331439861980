import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { instance } from 'utils/axios';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { firebaseConfig } from '../config';
// ----------------------------------------------------------------------

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
}

const initialState = {
  isFetchingUser: false,
  isAuthenticated: false,
  isOnboardingDone: false,
  isVerified: true,
  isInitialized: false,
  user: null
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user, isOnboardingDone } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isOnboardingDone,
      isInitialized: true,
      isFetchingUser: false,
      user
    };
  }
  if (action.type === 'VERIFY') {
    const { isVerified, isAuthenticated, user } = action.payload;
    return {
      ...state,
      isVerified,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }
  if (action.type === 'FETCH') {
    const { isFetchingUser } = action.payload;
    return {
      ...state,
      isFetchingUser
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  onboarding: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  loginWithFaceBook: () => Promise.resolve(),
  loginWithTwitter: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  changePassword: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          if (!user.emailVerified) {
            dispatch({
              type: 'VERIFY',
              payload: { isVerified: false, isAuthenticated: true, user }
            });
            user.sendEmailVerification();
            navigate('/auth/verify');
            return;
          }
          instance
            .post('users/detail', {})
            .then((response) => {
              setProfile(response.data.data);
              dispatch({
                type: 'INITIALISE',
                payload: { isAuthenticated: true, user, isOnboardingDone: true }
              });
            })
            .catch((error) => {
              if (error?.response?.data?.error === 'INVALID_USER') {
                dispatch({
                  type: 'INITIALISE',
                  payload: { isAuthenticated: true, user, isOnboardingDone: false }
                });
              }
            });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null, isOnboardingDone: false }
          });
        }
      }),
    [dispatch]
  );

  const login = async (email, password) => {
    await firebase.auth().signInWithEmailAndPassword(email, password);
    dispatch({
      type: 'FETCH',
      payload: { isFetchingUser: true }
    });
  };

  const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithFaceBook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithTwitter = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const register = (
    email,
    password // , firstName, lastName) =>
  ) => firebase.auth().createUserWithEmailAndPassword(email, password);
  // .then((res) => {
  //   firebase.firestore().collection('users').doc(res.user.uid).set({
  //     uid: res.user.uid,
  //     email
  //     // displayName: `${firstName} ${lastName}`
  //   });
  // });

  const onboarding = async (firstName, lastName, username, displayName) => {
    const response = await instance.post('auth/register_user', {
      user_firstname: firstName,
      user_lastname: lastName,
      user_email: firebase.auth().currentUser.email,
      user_username: username,
      user_display_name: displayName
    });
    if (response) {
      setProfile(response?.data?.data);
      dispatch({
        type: 'INITIALISE',
        payload: { isAuthenticated: true, user: firebase.auth().currentUser, isOnboardingDone: true }
      });
    }
  };
  // firebase.auth().createUserWithEmailAndPassword(email, password);
  // .then((res) => {
  //   firebase.firestore().collection('users').doc(res.user.uid).set({
  //     uid: res.user.uid,
  //     email
  //     // displayName: `${firstName} ${lastName}`
  //   });
  // });

  const logout = async () => {
    await firebase.auth().signOut();
  };

  const changePassword = async (oldPassword, newPassword) => {
    const user = firebase.auth().currentUser;

    if (user && user.providerData[0]?.providerId === 'password') {
      const credential = firebase.auth.EmailAuthProvider.credential(user.email, oldPassword);

      const reauthSuccess = await user.reauthenticateWithCredential(credential);

      if (reauthSuccess)
        user
          .updatePassword(newPassword)
          .then(() => {})
          .catch((error) => {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
            console.log(error);
          });
    }
  };

  const updateProfile = async (postData) => {
    console.log(postData);
  };

  const resetPassword = async (email) => {
    const actionCodeSettings = {
      // After password reset, the user will be give the ability to go back
      // to this page.
      url: process.env.REACT_APP_FIREBASE_REDIRECT_URL,
      handleCodeInApp: false
    };
    await firebase.auth().sendPasswordResetEmail(email, actionCodeSettings);
  };

  const auth = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          ...profile
        },
        login,
        register,
        onboarding,
        loginWithGoogle,
        loginWithFaceBook,
        loginWithTwitter,
        logout,
        resetPassword,
        updateProfile,
        changePassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
